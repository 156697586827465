import React, { memo, useState, useRef, useEffect } from "react";
import { Handle, Position, NodeProps } from "reactflow";
import cx from "classnames";
import { motion } from "motion/react";

import styles from "./NodeTypes.module.css";
import useNodeClickHandler from "../hooks/useNodeClick";
import zIndex from "@mui/material/styles/zIndex";

const WorkflowNode = ({ id, data }: NodeProps) => {
  const [label, setLabel] = useState(data.label);
  const nodeRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const onClick = useNodeClickHandler(id);
  const [isFocused, setIsFocused] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLabel(e.target.value);
    data.label = e.target.value;
  };

  useEffect(() => {
    setLabel(data.label);
  }, [data.label]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight - 16
      }px`;
      if (nodeRef.current) {
        nodeRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }
  }, [label]);

  return (
    <div ref={nodeRef} className={cx(styles.node)}>
      <div
        className={styles.inputContainer}
        tabIndex={0}
        onFocus={() => setIsFocused(true)}
        onBlur={(e) => {
          // If focus moves entirely outside this container, hide the button
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setIsFocused(false);
          }
        }}
      >
        <textarea
          ref={textareaRef}
          className={styles.textarea}
          onChange={onChange}
          value={label}
          rows={1}
          style={{ lineHeight: "normal" }}
          title="click to add a child node"
        />
        {isFocused && (
          <motion.div
            style={{
              position: "relative",
              top: 2,
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              width: "20px",
              height: "32px",
              zIndex: 1,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <motion.div
              className={styles.button}
              onClick={onClick}
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.2,
                y: { duration: 0.4, type: "spring" },
              }}
            >
              <img
                className={styles.plus}
                src="../+.svg"
                alt="Add Child Node"
              />
            </motion.div>
          </motion.div>
        )}
      </div>

      <Handle
        className={styles.handle}
        type="target"
        position={Position.Top}
        isConnectable={false}
      />
      <Handle
        className={styles.handle}
        type="source"
        position={Position.Bottom}
        isConnectable={false}
      />
    </div>
  );
};

export default memo(WorkflowNode);
