import { useCallback, useState, useContext } from "react";
import { NodeProps, useReactFlow } from "reactflow";

import { uuid } from "../utils";
import { PanelContext } from "../PanelContext";

const formatPrevious = (nodes: any[]) => {
  let labels = nodes
    .filter((node) => node.id !== "root")
    .filter((node) => node.id !== "1")
    .map((node) => node.data.label)
    .join("; ");
  return labels
    ? `These are the already covered statements that should not covered again in any way: ${labels}`
    : "";
};

export function useNodeClick(id: NodeProps["id"]) {
  const { setEdges, setNodes, getNodes, getEdges, getNode } = useReactFlow();

  const {
    constraint,
    desirability,
    gptFour,
    objective,
    opportunity,
    sdg,
    temperature,
    unknown,
  } = useContext(PanelContext);

  const [childNode, setChildNode] = useState({});
  const [childEdge, setChildEdge] = useState({});

  const onClick = useCallback(async () => {
    console.log("clicked", id);

    const parentNode = getNode(id);
    if (!parentNode) return;

    const childNodeId = uuid();
    const adjustedTemperature = temperature / 50;
    const adjustedUnknown = unknown / 25 - 2;
    const nodes = getNodes();

    let opportunityWord = "no";
    if (opportunity < 30) {
      opportunityWord = "low";
    } else if (opportunity > 70) {
      opportunityWord = "high";
    }

    let desirabilityWord = "no";
    if (desirability < 30) {
      desirabilityWord = "low";
    } else if (desirability > 70) {
      desirabilityWord = "high";
    }

    let sdgWord = "no";
    if (sdg < 30) {
      sdgWord = "low";
    } else if (sdg > 70) {
      sdgWord = "high";
    }

    const assumptionArray = [
      {
        role: "assistant",
        content: formatPrevious(nodes),
      },
      {
        role: "system",
        content: `I want you to act as a critic. Challenge the thinking by giving a hidden and unconscious presumption contained in the statement. The presumption should address potential factors that may give rise to unintended side effects and inadvertently bolster undesirable outcomes. Focus on elements of the statement that are considered valid but lack a solid foundation. The hidden presumption should follow this sentence without writing the sentence: "For the given idea to be true ..." and then the presumption`,
      },
      {
        role: "user",
        content: `Statement: ${parentNode.data.label}`,
      },
      {
        role: "system",
        content: `Do not write the sentence "For the given idea to be true ...". Formulate your answer as a plain statement without any introduction that can be validated. Write a maximum of 10 words in the form of a statement that should be at an easy-to-read B1 English level. At no point write the following words in the answer: "assume", "presume", "Assumption", "Presumption", "Hidden Assumption", or "Hidden presumption".`,
      },
      {
        role: "assistant",
        content: formatPrevious(nodes),
      },
    ];

    const nodeHeight = parentNode.height || 0;

    const newChildNode = {
      id: childNodeId,
      position: {
        x: parentNode.position.x,
        y: parentNode.position.y + nodeHeight + 200,
      },
      type: "workflow",
      data: { label: "" },
    };

    const newChildEdge = {
      id: `${parentNode.id}=>${childNodeId}`,
      source: parentNode.id,
      target: childNodeId,
      type: "smoothstep",
      borderRadius: 100,
      style: { stroke: "#e4e4e7", strokeWidth: 2 },
    };

    setChildNode(newChildNode);
    setChildEdge(newChildEdge);
    setNodes((nodes) => nodes.concat([newChildNode]));
    setEdges((edges) => edges.concat([newChildEdge]));

    const response = await fetch("/api/generate", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify([
        assumptionArray,
        adjustedTemperature,
        adjustedUnknown,
        gptFour,
      ]),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const data = await response.body;
    if (!data) return;

    const reader = data.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      const chunkValue = decoder.decode(value);

      // Update the label of the child node
      setNodes((oldNodes) =>
        oldNodes.map((node) =>
          node.id === childNodeId
            ? {
                ...node,
                data: { ...node.data, label: node.data.label + chunkValue },
              }
            : node
        )
      );
    }
  }, [
    getEdges,
    getNode,
    getNodes,
    id,
    setEdges,
    setNodes,
    objective,
    constraint,
    temperature,
    unknown,
    childEdge,
    childNode,
    gptFour,
  ]);

  return onClick;
}

export default useNodeClick;
